import React, { useState, forwardRef } from "react"
import DatePicker from "react-datepicker"
import { IoPushOutline } from "react-icons/io5"

import { useAddCartAttribute } from "~/provider/context-provider"
import { getPrice } from "~/utils"

const CartAttributeForm = ({ checkout, shopClosed = false }) => {
  const addCartAttributes = useAddCartAttribute()
  const [disableCheckout, setDisableCheckout] = useState(true)
  const [deliveryDate, setDate] = useState("")

  const existNotes = checkout.customAttributes.find(
    att => att.key === "Specifications"
  )

  const existMessage = checkout.customAttributes.find(
    att => att.key === "Message"
  )

  const [message, setMessage] = useState(existMessage ? existMessage.value : "")
  const [notes, setNotes] = useState(existNotes ? existNotes.value : "")

  let minDate = new Date()
  let maxDate = undefined

  if (Boolean(minDate.getHours() >= 11 && minDate.getMinutes() > 0)) {
    minDate.setDate(minDate.getDate() + 1)
  }

  // const valentinesDayProducts = [
  //   "Wrapped Up In Love!",
  //   "Nuts About You!",
  //   "Hey There Sugar!",
  //   "A Whole Lotta Love!",
  //   "Spread the Love",
  // ]

  // const mothersDayProducts = ["Mama I Love Ya!"]

  // const items = checkout.lineItems.map(li => li.title)
  // const hasValentines = items.some(r => valentinesDayProducts.indexOf(r) >= 0)
  // const hasMothers = items.some(r => mothersDayProducts.indexOf(r) >= 0)
  // const hasOthers = items.some(r => mothersDayProducts.indexOf(r) === -1)

  // if (hasValentines) {
  //   minDate = new Date("02-14-2023")
  //   maxDate = new Date("02-14-2023")
  // }

  // if (hasMothers) {
  //   minDate = new Date("03-09-2024")
  //   maxDate = new Date("03-09-2024")
  // }

  // useEffect(() => {
  //   // if (hasValentines) {
  //   //   setDate(new Date("02-14-2023"))
  //   // }

  //   // if (hasMothers) {
  //   //   setDate(new Date("03-09-2024"))
  //   // }
  // }, [hasMothers])

  // const isMothersDay =
  //   new Date(deliveryDate).toDateString() === "Sat Mar 09 2024"

  const handleOnSubmit = async evt => {
    evt.preventDefault()

    const input = {
      customAttributes: [
        { key: "Message", value: message },
        { key: "Specifications", value: notes },
        {
          key: "Delivery Date",
          value: new Date(deliveryDate).toDateString(),
        },
      ],
    }

    setDisableCheckout(true)

    const windowRef = window.open("", "_blank noreferrer noopener")

    addCartAttributes(input).then(newCheckout => {
      windowRef.document.location.href = newCheckout.webUrl
      setDisableCheckout(false)
    })
  }

  const handleOnMessageChange = e => setMessage(e.target.value)
  const handleOnNoteChange = e => setNotes(e.target.value)

  const isWeekday = date => {
    // if (hasMothers || hasValentines) return true
    const day = date.getDay()
    return day !== 0 && day !== 1 && day !== 2 && day !== 3
  }

  const DateInput = forwardRef(({ value, onClick }, ref) => {
    const placeholder = (
      <span className="text-gray-700 flex items-center justify-between">
        Please choose a delivery date <IoPushOutline className="mt-1 w-5 h-5" />
      </span>
    )

    return (
      <button
        className="w-full h-11 md:w-72 border text-left py-2 px-4 focus:ring-amber-500 focus:border-amber-500 block shadow-sm sm:text-sm border-gray-300 rounded"
        onClick={onClick}
        ref={ref}
        type="button"
      >
        {value !== "" ? value : placeholder}
      </button>
    )
  })

  return (
    <form onSubmit={handleOnSubmit}>
      {/* {((hasOthers && isMothersDay) || (hasOthers && hasMothers)) && (
        <div className="mb-8 text-red-700">
          <p>
            Sorry, only Mothers Day Bouquets are available on the 9th March!
          </p>
        </div>
      )} */}

      <fieldset disabled>
        <div className="space-y-5">
          <div>
            <label
              htmlFor="deliveryDate"
              className="block text-base font-medium text-gray-700 mb-1"
            >
              Delivery Date
            </label>

            <DatePicker
              dateFormat="dd/MM/yyyy"
              disabled={true}
              selected={deliveryDate}
              showPopperArrow={false}
              onChange={date => setDate(date)}
              excludeDateIntervals={[]}
              customInput={<DateInput />}
              minDate={minDate}
              maxDate={maxDate}
              filterDate={isWeekday}
              popperPlacement="bottom-start"
            />

            {/* {hasValentines && (
              <p className="mt-2 text-sm text-stone-500">
                Valentines bouquets are only available for delivery on February
                14th!!
              </p>
            )} */}

            {/* {hasMothers && (
              <p className="mt-2 text-sm text-stone-500">
                Mother's Day bouquets are only available for delivery on March
                9th!!
              </p>
            )} */}
          </div>

          <div>
            <label
              htmlFor="message"
              className="block text-base font-medium text-gray-700"
            >
              Message
            </label>

            <textarea
              id="message"
              name="message"
              value={message}
              onChange={handleOnMessageChange}
              rows="3"
              style={{ minHeight: "6rem" }}
              className="shadow-sm focus:ring-amber-500 focus:border-amber-500 mt-1 block w-full sm:text-sm border-gray-300 rounded resize-y h-24"
              placeholder="Would you like to include a message with your flowers?"
            />
          </div>

          <div>
            <label
              htmlFor="notes"
              className="block text-base font-medium text-gray-700"
            >
              Specifications
            </label>

            <textarea
              id="notes"
              name="notes"
              value={notes}
              onChange={handleOnNoteChange}
              rows="3"
              style={{ minHeight: "6rem" }}
              className="shadow-sm focus:ring-amber-500 focus:border-amber-500 mt-1 block w-full sm:text-sm border-gray-300 rounded resize-y h-24"
              placeholder="Please let me know if you would like any specific colours or flowers and I will do my best to accomodate!"
            />
          </div>
        </div>

        <div className="flex flex-col sm:flex-row items-center justify-between pt-4 border-t border-orange mt-5">
          <h4 className="text-lg font-medium py-5">
            Total:
            <span className="price ml-3 text-lg font-medium">
              {getPrice(checkout.paymentDueV2.amount)}
            </span>
          </h4>

          <button
            className="text-center w-52 font-semibold text-white disabled:opacity-50 hover:bg-opacity-95 bg-orange py-3 px-6 uppercase rounded"
            disabled={
              !checkout.lineItems.length ||
              deliveryDate === "" ||
              disableCheckout
            }
            type="submit"
          >
            Check Out
          </button>
        </div>
      </fieldset>
    </form>
  )
}

export default CartAttributeForm
